<template>
  <div v-show="open" v-html="legacySystemHTML"></div>
</template>

<script>
import toSvg from "./../toSvgTelecontrol";
export default {
  data() {
    return {
      open: false,
      legacySystemHTML: `
      <?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!-- Created with Inkscape (http://www.inkscape.org/) -->

<svg
   width="77.222649mm"
   height="80.190201mm"
   viewBox="0 0 77.222649 99.190201"
   version="1.1"
   id="svg5"
   xml:space="preserve"
   inkscape:version="1.2 (dc2aedaf03, 2022-05-15)"
   sodipodi:docname="M_A_5CFS_3Dec_telecontrol.svg"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg"><sodipodi:namedview
     id="namedview7"
     pagecolor="#ffffff"
     bordercolor="#000000"
     borderopacity="0.25"
     inkscape:showpageshadow="2"
     inkscape:pageopacity="0.0"
     inkscape:pagecheckerboard="0"
     inkscape:deskcolor="#d1d1d1"
     inkscape:document-units="mm"
     showgrid="true"
     inkscape:zoom="1"
     inkscape:cx="266"
     inkscape:cy="171"
     inkscape:window-width="1362"
     inkscape:window-height="1001"
     inkscape:window-x="434"
     inkscape:window-y="63"
     inkscape:window-maximized="0"
     inkscape:current-layer="layer1"><inkscape:grid
       type="xygrid"
       id="grid107586" /></sodipodi:namedview><defs
     id="defs2"><linearGradient
       id="linearGradient10077"
       inkscape:swatch="solid"><stop
         style="stop-color:#ffffff;stop-opacity:0.99215686;"
         offset="0"
         id="stop10075" /></linearGradient><filter
       inkscape:collect="always"
       style="color-interpolation-filters:sRGB"
       id="filter4555-7"
       x="-1.0552142e-05"
       width="1.0000211"
       y="-0.00010639225"
       height="1.0002128"><feGaussianBlur
         inkscape:collect="always"
         stdDeviation="0.0011536701"
         id="feGaussianBlur4557-9" /></filter><style
       type="text/css"
       id="style2852">
   <![CDATA[
    .str0 {stroke:#1F1A17;stroke-width:0.374252}
    .str2 {stroke:#1F1A17;stroke-width:0.374252;stroke-linecap:round}
    .str1 {stroke:#1F1A17;stroke-width:0.374252;stroke-dasharray:1.871260 1.122756}
    .str4 {stroke:#1F1A17;stroke-width:0.374252;stroke-linecap:round;stroke-dasharray:1.871260 1.122756}
    .str3 {stroke:#1F1A17;stroke-width:0.374252;stroke-linecap:round;stroke-dasharray:1.871260 1.871260}
    .fil0 {fill:none}
    .fil1 {fill:#1F1A17;fill-rule:nonzero}
   ]]>
  </style><style
       id="style23281">.cls-1{fill:none;}</style></defs><g
     inkscape:label="Capa 1"
     inkscape:groupmode="layer"
     id="layer1"
     transform="translate(-85.227387,-90.921029)"><g
       id="Capa_10_copia"
       transform="matrix(0.26458333,0,0,0.26458333,-8.7084723,51.930251)">
	</g><path
       id="rect3318-5"
       style="fill:#ebeeef;fill-opacity:1;stroke-width:0.44144"
       d="m 85.814304,159.47418 h 76.634916 v 30.63705 H 85.814304 Z" /><path
       id="rect3320-1"
       style="fill:#003c89;fill-opacity:1;stroke-width:1.00014;filter:url(#filter4555-7)"
       d="M 3.9756525,175.42555 H 266.36866 v 26.02453 H 3.9756525 Z"
       transform="matrix(0.29206216,0,0,0.25016633,84.653018,115.5886)" /><text
       xml:space="preserve"
       style="font-size:4.61949px;line-height:1.25;font-family:sans-serif;fill:#ffffff;stroke-width:0.28872"
       x="112.64575"
       y="165.94705"
       id="text294-5"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         id="tspan292-7"
         x="112.64575"
         y="165.94705"
         style="font-size:3.46461px;fill:#ffffff;stroke-width:0.28872">Información</tspan></text><text
       xml:space="preserve"
       style="font-size:4.79112px;line-height:1.25;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.299447"
       x="88.905998"
       y="177.71822"
       id="text294-2-5"
       transform="scale(1.0491626,0.95314111)"><tspan
         sodipodi:role="line"
         id="tspan292-1-99"
         x="88.905998"
         y="177.71822"
         style="font-size:3.59333px;fill:#232626;fill-opacity:1;stroke-width:0.299447"><tspan
           style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.299447"
           id="tspan478-4">ID Disp. :</tspan></tspan></text><text
       xml:space="preserve"
       style="font-size:4.61949px;line-height:1.25;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.28872"
       x="96.636139"
       y="176.75664"
       id="text294-2-2-4-3"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         id="tspan292-1-1-9-9"
         x="84.636139"
         y="176.75664"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.28872">Alias :</tspan></text><text
       xml:space="preserve"
       style="font-size:4.61949px;line-height:1.25;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.28872"
       x="90.514687"
       y="180.34782"
       id="text294-2-0-10"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         id="tspan292-1-9-2"
         x="90.514687"
         y="180.34782"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.28872">Ubicación :</tspan></text><text
       xml:space="preserve"
       style="font-size:4.58558px;line-height:0.85;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.286601"
       x="84.874893"
       y="183.35675"
       id="text294-2-8-2"
       transform="scale(1.0041531,0.99586407)"><tspan
         sodipodi:role="line"
         x="84.874893"
         y="183.35675"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.286601"
         id="tspan380-3">  Ult. medición :</tspan></text><text
       xml:space="preserve"
       style="font-size:4.07968px;line-height:1.25;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.28872"
       x="111.78088"
       y="171.38197"
       id="text294-2-02-5"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         id="txt-device"
         x="111.78088"
         y="171.38197"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.28872">00000000</tspan></text><text
       xml:space="preserve"
       style="font-size:4.61949px;line-height:0.85;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.28872"
       x="92.910698"
       y="189.25705"
       id="text294-2-8-9-5"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         x="92.910698"
         y="189.25705"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.28872"
         id="tspan380-4-4">  Pulsos  :</tspan></text><text
       xml:space="preserve"
       style="font-size:4.61949px;line-height:1.25;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.28872"
       x="112.16678"
       y="175.80244"
       id="text294-2-2-6-2"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         id="txt-Alias"
         x="99.16678"
         y="175.80244"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.28872">-</tspan></text><text
       xml:space="preserve"
       style="font-size:4.61949px;line-height:1.25;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.28872"
       x="111.78037"
       y="180.34782"
       id="text294-2-0-1-5"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         id="txt-ubicacion"
         x="111.78037"
         y="180.34782"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.28872">0,0</tspan></text><text
       xml:space="preserve"
       style="font-size:4.61949px;line-height:0.85;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.28872"
       x="111.7187"
       y="184.71269"
       id="text294-2-8-0-0"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         x="111.7187"
         y="184.71269"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.28872"
         id="txt-UltimaAct">00/00/00   00:00:00</tspan></text><text
       xml:space="preserve"
       style="font-size:4.61949px;line-height:0.85;font-family:sans-serif;fill:#ffffff;stroke-width:0.28872"
       x="110.80153"
       y="189.07758"
       id="text294-2-8-9-7-3"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         x="110.80153"
         y="189.07758"
         style="font-size:4.07968px;fill:#1a1a1a;stroke-width:0.28872"
         id="tspan380-4-8-5">  </tspan></text><text
       xml:space="preserve"
       style="font-size:4.61949px;line-height:0.85;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.28872"
       x="111.91395"
       y="189.43652"
       id="text294-2-8-9-2-9"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         x="111.91395"
         y="189.43652"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.28872"
         id="txt-Pulsos">0</tspan></text><g
       id="Capa_x0020_1"
       inkscape:label="Capa 1"
       inkscape:groupmode="layer"
       transform="matrix(0.23517337,0,0,0.25639214,157.04571,70.316583)"
       style="clip-rule:evenodd;fill-rule:evenodd;image-rendering:optimizeQuality;shape-rendering:geometricPrecision;text-rendering:geometricPrecision">
  <metadata
   id="CorelCorpID_0Corel-Layer" />
 <g
   data-name="Layer 2"
   id="Layer_2"
   transform="matrix(1.1250565,0,0,1.0192143,-35.784344,98.528906)"
   style="display:inline;fill:#c83737;fill-opacity:0.9;stroke-width:1.00021;stroke-dasharray:none"><g
     id="S-Fraude"
     style="display:none;fill:#c83737;fill-opacity:0.9;stroke-width:1.00021;stroke-dasharray:none"><path
       d="M 22.7,28 H 9.3 a 6.25,6.25 0 0 1 -5.47,-3.15 6.15,6.15 0 0 1 0,-6.22 L 10.56,7.12 a 6.3,6.3 0 0 1 10.88,0 l 6.71,11.51 v 0 a 6.15,6.15 0 0 1 0,6.22 A 6.25,6.25 0 0 1 22.7,28 Z M 16,6 A 4.24,4.24 0 0 0 12.29,8.12 L 5.58,19.64 a 4.15,4.15 0 0 0 0,4.21 A 4.23,4.23 0 0 0 9.3,26 h 13.4 a 4.23,4.23 0 0 0 3.73,-2.15 4.15,4.15 0 0 0 0,-4.21 L 19.71,8.12 A 4.24,4.24 0 0 0 16,6 Z"
       id="path23287"
       style="fill:#c83737;fill-opacity:0.9;stroke-width:1.00021;stroke-dasharray:none" /><path
       class="cls-1"
       d="m 16,12 a 0.54,0.54 0 0 0 -0.44,0.22 0.52,0.52 0 0 0 -0.1,0.48 L 16,14.88 16.54,12.7 A 0.52,0.52 0 0 0 16.44,12.22 0.54,0.54 0 0 0 16,12 Z"
       id="path23289"
       style="fill:#c83737;fill-opacity:0.9;stroke-width:1.00021;stroke-dasharray:none" /><path
       d="m 18,11 a 2.56,2.56 0 0 0 -4,0 2.5,2.5 0 0 0 -0.46,2.19 L 15,19.24 a 1,1 0 0 0 1.94,0 l 1.51,-6.06 A 2.5,2.5 0 0 0 18,11 Z M 16.54,12.7 16,14.88 15.46,12.7 a 0.52,0.52 0 0 1 0.1,-0.48 0.55,0.55 0 0 1 0.88,0 0.52,0.52 0 0 1 0.1,0.48 z"
       id="path23291"
       style="fill:#c83737;fill-opacity:0.9;stroke-width:1.00021;stroke-dasharray:none" /><circle
       cx="16"
       cy="22.5"
       r="1.5"
       id="circle23293"
       style="fill:#c83737;fill-opacity:0.9;stroke-width:1.00021;stroke-dasharray:none" /></g></g><g
   id="frame"
   transform="matrix(0.91053163,0,0,0.83850055,-90.100031,336.92569)"><rect
     class="cls-1"
     height="32"
     width="32"
     id="rect23296"
     x="0"
     y="0" /></g><polygon
   style="display:inline;fill:#e6e6e6;fill-opacity:1"
   points="32,8.818 11.941,28.877 0,16.935 5.695,11.24 11.941,17.486 26.305,3.123 "
   id="checkConnect-5"
   transform="matrix(1.0071785,0,0,1.0856859,-26.521454,426.97583)" /></g><path
       d="m 152.27229,145.401 c -0.0815,0.0339 -0.14891,0.1433 -0.14891,0.24537 v 0.0898 l -0.80834,0.009 c -0.77571,0.009 -0.81532,0.0139 -0.94109,0.0631 -0.24464,0.10209 -0.41233,0.30116 -0.51016,0.60238 -0.0441,0.14096 -0.0466,0.22105 -0.0466,4.15132 0,3.93029 9.3e-4,4.0104 0.0466,4.15134 0.0977,0.30116 0.26552,0.50035 0.51016,0.60238 l 0.13043,0.0558 h 2.56239 2.5624 l 0.13281,-0.0558 c 0.1864,-0.0777 0.3494,-0.2405 0.44256,-0.43726 l 0.0768,-0.16273 0.007,-4.10272 c 0.005,-4.53026 0.0153,-4.23148 -0.13993,-4.4744 -0.0417,-0.0654 -0.12805,-0.15786 -0.19335,-0.20888 -0.21433,-0.16764 -0.23991,-0.1725 -1.06222,-0.1846 l -0.73847,-0.009 -0.0119,-0.10935 c -0.007,-0.0753 -0.03,-0.1311 -0.0724,-0.17492 l -0.0603,-0.0632 -0.84558,-0.006 c -0.46357,-8.9e-4 -0.86657,0.006 -0.89221,0.017 z m 3.35209,0.81132 c 0.0908,0.0387 0.21897,0.18462 0.24227,0.27693 0.0236,0.0851 0.0236,8.06215 0,8.14716 -0.0234,0.0922 -0.15128,0.23805 -0.24227,0.27692 -0.11195,0.0511 -5.00365,0.0511 -5.11551,0 -0.10023,-0.041 -0.21662,-0.18457 -0.24693,-0.2988 -0.0346,-0.12864 -0.0346,-7.9747 0,-8.10341 0.028,-0.1115 0.14688,-0.25505 0.2423,-0.2988 0.10448,-0.0484 5.01065,-0.0484 5.12014,0 z"
       id="Battery_Level"
       style="display:inline;opacity:0.906452;fill:#000000;fill-opacity:1;stroke:none;stroke-width:1.73255;stroke-miterlimit:4;stroke-dasharray:none" /><path
       d="m 150.8307,147.43493 v 0.50693 h 2.20831 2.20826 v -0.50693 -0.50696 h -2.20826 -2.20831 z"
       id="bat_L5"
       style="display:inline;opacity:0.906452;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.00712524;paint-order:stroke fill markers" /><path
       d="m 150.8307,149.01077 v 0.50696 h 2.20831 2.20826 v -0.50696 -0.50695 h -2.20826 -2.20831 z"
       id="bat_L4"
       style="display:inline;opacity:0.906452;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.0071252" /><path
       d="m 150.8307,150.51812 v 0.50693 h 2.20831 2.20826 v -0.50693 -0.50694 h -2.20826 -2.20831 z"
       id="bat_L3"
       style="display:inline;opacity:0.906452;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.00712529" /><path
       d="m 150.8307,152.09401 v 0.50693 h 2.20831 2.20826 v -0.50693 -0.50694 h -2.20826 -2.20831 z"
       id="bat_L2"
       style="display:inline;opacity:0.906452;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.00712524" /><path
       d="m 150.8307,153.66988 v 0.50694 h 2.20831 2.20826 v -0.50694 -0.50693 h -2.20826 -2.20831 z"
       id="bat_L1"
       style="display:inline;opacity:0.906452;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.00712532" /><g
       id="g76451"
       style="display:inline;fill:#1a1a1a;fill-opacity:1;stroke-width:12.3402;stroke-dasharray:none"
       transform="matrix(0.30487124,0,0,0.321627,80.093256,257.86033)"><g
         id="sFraude"
         style="fill:#1a1a1a"><g
           id="g27831"
           style="fill:#1a1a1a"><g
             id="g31956"
             transform="matrix(0.07298313,0,0,0.06903844,38.558924,-350.55249)"
             style="fill:#1a1a1a"><g
               id="g31865"
               style="fill:#1a1a1a">
	<g
   id="g31863"
   style="fill:#1a1a1a">
		<path
   d="M 394.095,330.155 C 374.315,290.586 373.76,249.848 373.76,249.447 v -46.08 c 0,-64.93 -52.83,-117.76 -117.76,-117.76 -64.93,0 -117.76,52.83 -117.76,117.76 v 46.08 c 0,0.333 -0.478,40.986 -20.335,80.708 -3.968,7.936 -3.541,17.357 1.118,24.909 4.668,7.552 12.902,12.143 21.777,12.143 h 65.809 c -1.109,4.087 -1.809,8.354 -1.809,12.8 0,28.237 22.963,51.2 51.2,51.2 28.237,0 51.2,-22.963 51.2,-51.2 0,-4.446 -0.7,-8.713 -1.809,-12.8 H 371.2 c 8.875,0 17.109,-4.591 21.777,-12.143 4.668,-7.544 5.086,-16.973 1.118,-24.909 z M 256,405.606 c -14.114,0 -25.6,-11.486 -25.6,-25.6 0,-4.762 1.664,-8.977 3.934,-12.8 h 43.332 c 2.27,3.823 3.934,8.038 3.934,12.8 0,14.115 -11.486,25.6 -25.6,25.6 z m -115.2,-64 c 23.04,-46.08 23.04,-92.16 23.04,-92.16 v -46.08 c 0,-50.901 41.259,-92.16 92.16,-92.16 50.901,0 92.16,41.259 92.16,92.16 v 46.08 c 0,0 0,46.08 23.04,92.16 -23.04,0 -207.36,0 -230.4,0 z"
   id="path31861"
   style="fill:#1a1a1a" />
	</g>
</g><g
               id="g31871"
               style="fill:#1a1a1a">
	<g
   id="g31869"
   style="fill:#1a1a1a">
		<path
   d="m 428.809,108.16 -21.598,13.679 c 17.63,27.733 27.989,60.527 27.989,95.761 0,35.234 -10.359,68.028 -27.998,95.761 L 428.8,327.04 c 20.156,-31.701 32,-69.18 32,-109.44 0,-40.26 -11.844,-77.739 -31.991,-109.44 z"
   id="path31867"
   style="fill:#1a1a1a" />
	</g>
</g><g
               id="g31877"
               style="fill:#1a1a1a">
	<g
   id="g31875"
   style="fill:#1a1a1a">
		<path
   d="M 104.798,121.839 83.191,108.16 C 63.044,139.861 51.2,177.34 51.2,217.6 c 0,40.26 11.844,77.739 31.991,109.44 l 21.598,-13.679 C 87.159,285.628 76.8,252.834 76.8,217.6 c 0,-35.234 10.359,-68.028 27.998,-95.761 z"
   id="path31873"
   style="fill:#1a1a1a" />
	</g>
</g><g
               id="g31883"
               style="fill:#1a1a1a">
	<g
   id="g31881"
   style="fill:#1a1a1a">
		<path
   d="m 472.013,80.794 -21.606,13.679 c 22.664,35.669 35.993,77.832 35.993,123.127 0,45.295 -13.329,87.458 -35.994,123.127 l 21.606,13.679 C 497.195,314.778 512,267.93 512,217.6 512,167.27 497.195,120.422 472.013,80.794 Z"
   id="path31879"
   style="fill:#1a1a1a" />
	</g>
</g><g
               id="g31889"
               style="fill:#1a1a1a">
	<g
   id="g31887"
   style="fill:#1a1a1a">
		<path
   d="M 61.594,94.473 39.987,80.794 C 14.805,120.422 0,167.27 0,217.6 0,267.93 14.805,314.778 39.987,354.406 L 61.593,340.727 C 38.929,305.058 25.6,262.895 25.6,217.6 25.6,172.305 38.929,130.142 61.594,94.473 Z"
   id="path31885"
   style="fill:#1a1a1a" />
	</g>
</g><g
               id="g31891"
               style="fill:#1a1a1a">
</g><g
               id="g31893"
               style="fill:#1a1a1a">
</g><g
               id="g31895"
               style="fill:#1a1a1a">
</g><g
               id="g31897"
               style="fill:#1a1a1a">
</g><g
               id="g31899"
               style="fill:#1a1a1a">
</g><g
               id="g31901"
               style="fill:#1a1a1a">
</g><g
               id="g31903"
               style="fill:#1a1a1a">
</g><g
               id="g31905"
               style="fill:#1a1a1a">
</g><g
               id="g31907"
               style="fill:#1a1a1a">
</g><g
               id="g31909"
               style="fill:#1a1a1a">
</g><g
               id="g31911"
               style="fill:#1a1a1a">
</g><g
               id="g31913"
               style="fill:#1a1a1a">
</g><g
               id="g31915"
               style="fill:#1a1a1a">
</g><g
               id="g31917"
               style="fill:#1a1a1a">
</g><g
               id="g31919"
               style="fill:#1a1a1a">
</g></g></g></g></g><polygon
       style="display:none;fill:#00ff00;fill-opacity:1"
       points="26.305,3.123 32,8.818 11.941,28.877 0,16.935 5.695,11.24 11.941,17.486 "
       id="checkConnect"
       transform="matrix(0.23686156,0,0,0.27836132,150.56012,179.47822)" /><g
       id="g14743"
       style="display:inline"
       transform="matrix(3.3797487,0,0,3.48856,-296.44155,-400.02937)"><path
         style="fill:#003c89;stroke:#1f1a17;stroke-width:0.82067;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;paint-order:stroke fill markers"
         d="m 114.21313,151.41877 h 4.69748"
         id="path4874" /><path
         style="fill:#003c89;stroke:#1f1a17;stroke-width:0.82067;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;paint-order:stroke fill markers"
         d="m 129.40363,151.37496 h 4.6975"
         id="path4874-8" /><path
         style="fill:#003c89;stroke:#1f1a17;stroke-width:0.713082;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;paint-order:stroke fill markers"
         d="m 118.96977,147.77081 0.0255,7.10768"
         id="path6336" /><path
         style="fill:#003c89;stroke:#1f1a17;stroke-width:0.713082;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;paint-order:stroke fill markers"
         d="m 129.30072,147.87699 0.0256,7.10765"
         id="path6336-1" /><path
         style="fill:#003c89;stroke:#1f1a17;stroke-width:0.722807;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;paint-order:stroke fill markers"
         d="m 124.12535,145.61471 0.033,4.93144"
         id="path6336-1-5" /><path
         style="fill:#003c89;stroke:#1f1a17;stroke-width:0.713082;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;paint-order:stroke fill markers"
         d="m 119.05069,154.8646 10.25382,-6.96013"
         id="path6414" /><path
         style="fill:#003c89;stroke:#1f1a17;stroke-width:0.713082;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;paint-order:stroke fill markers"
         d="m 118.97911,147.75637 9.90067,6.91658 0.40684,0.28421"
         id="path6416"
         sodipodi:nodetypes="ccc" /><ellipse
         style="display:inline;fill:#ffffff;fill-opacity:1;stroke:#1f1a17;stroke-width:1.34945;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;paint-order:stroke fill markers"
         id="path6390"
         cx="124.15238"
         cy="151.39345"
         rx="2.0988505"
         ry="2.1219249" /><ellipse
         style="display:inline;fill:#ffffff;fill-opacity:1;stroke:#1f1a17;stroke-width:0.943772;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;paint-order:stroke fill markers"
         id="path6390-9"
         cx="124.12542"
         cy="144.27608"
         rx="1.1400089"
         ry="1.1817735" /><text
         xml:space="preserve"
         style="font-size:1.89555px;line-height:1.25;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.118472"
         x="124.05163"
         y="143.9845"
         id="text294-2-2-4"
         transform="scale(0.99460361,1.0054257)"><tspan
           sodipodi:role="line"
           id="tspan292-1-1-9"
           x="124.05163"
           y="143.9845"
           style="font-size:1.2637px;fill:#232626;fill-opacity:1;stroke-width:0.118472"><tspan
   style="font-size:1.63659px;stroke-width:0.118472"
   id="tspan14711">M</tspan> </tspan></text></g><g
       id="KM1"
       style="display:inline"><rect
         style="display:inline;fill:#ffffff;fill-opacity:1;stroke:#ffffff;stroke-width:5.81337;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1;paint-order:stroke fill markers"
         id="rect18752"
         width="18.092106"
         height="3.3407004"
         x="114.26294"
         y="126.34528" /><path
         style="display:inline;fill:#003c89;stroke:#1f1a17;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;paint-order:stroke fill markers"
         d="m 115.20241,128.02877 h 15.85312"
         id="path4874-8-0" /><path
         style="display:inline;fill:#003c89;stroke:#1f1a17;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;paint-order:stroke fill markers"
         d="m 127.80101,126.35616 0.60079,0.28969 2.72202,1.31249"
         id="path4874-8-0-9"
         sodipodi:nodetypes="ccc" /><path
         style="display:inline;fill:#003c89;stroke:#1f1a17;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;paint-order:stroke fill markers"
         d="m 131.12666,128.06261 -0.60079,0.28969 -2.72202,1.31249"
         id="path4874-8-0-9-1"
         sodipodi:nodetypes="ccc"
         inkscape:transform-center-x="3.2742112"
         inkscape:transform-center-y="0.23151038" /></g></g><style
     type="text/css"
     id="style11901">
	.st0{fill:#333435;}
	.st1{font-family:'MyriadPro-Regular';}
	.st2{font-size:15.1008px;}
	.st3{font-size:18px;}
	.st4{font-size:21px;}
	.st5{font-size:13.4951px;}
	.st6{font-size:12.1191px;}
	.st7{font-size:16.5828px;}
	.st8{font-size:9.9589px;}
	.st9{font-family:'Calculator';}
	.st10{font-size:45.1149px;}
	.st11{letter-spacing:4;}
	.st12{font-size:23px;}
	.st13{fill:#BABCBF;}
	.st14{fill:#A2A4A8;}
	.st15{fill:#202024;}
	.st16{fill:#D6D7D9;}
	.st17{fill:#BCBEBF;}
	.st18{fill:#4E4E53;}
	.st19{fill:#A84B49;}
	.st20{fill:#C1C1BD;}
	.st21{fill:#101012;}
	.st22{fill:#F7F7F7;}
	.st23{fill:#8B8D8F;}
	.st24{fill:#C7DA9D;}
	.st25{fill:#6D454A;}
	.st26{fill:#A35559;}
	.st27{fill:#282834;}
</style></svg>

          `,
    };
  },
  methods: {
    async showSvg(msg) {
      this.open = true;
      const { check, bateria, detalle, campana, statusControl } =
        toSvg.toParseSvg(msg);
      detalle.forEach((element) => {
        document.getElementById(element.elementId).textContent =
          element.textContent;
      });
      bateria.forEach((element) => {
        document.getElementById(element.elementId).style.display =
          element.attributeValue;
      });
      campana.forEach((element) => {
        document.getElementById(element.elementId).style.display =
          element.attributeValue;
      });
      check.forEach((element) => {
        document.getElementById(element.elementId).style.display =
          element.attributeValue;
      });
      statusControl.forEach((element) => {
        document.getElementById(element.elementId).style.display =
          element.attributeValue;
      });
      const levels = ["bat_L1", "bat_L2", "bat_L3", "bat_L4", "bat_L5"];

      for (let i = 0; i < levels.length; i++) {
        if (
          msg.porcentajeBateria > i * 20 &&
          msg.porcentajeBateria <= (i + 1) * 20
        ) {
          for (let j = i; j < levels.length; j++) {
            document.getElementById(levels[j]).style.display = "none";
          }
          break;
        }
      }
    },
    close() {
      this.open = false;
    },
  },
};
</script>

<style></style>
