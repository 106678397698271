<template>
  <div v-show="open" v-html="legacySystemHTML"></div>
</template>

<script>
import toSvg from "./../toSvg";
export default {
  data() {
    return {
      open: false,
      legacySystemHTML: `
      <?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!-- Created with Inkscape (http://www.inkscape.org/) -->

<svg
   width="77.222649mm"
   height="80.190201mm"
   viewBox="0 0 77.222649 99.190201"
   version="1.1"
   id="svg5"
   xml:space="preserve"
   inkscape:version="1.2 (dc2aedaf03, 2022-05-15)"
   sodipodi:docname="M_A_5CFS_3Dec_Med.svg"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg"><sodipodi:namedview
     id="namedview7"
     pagecolor="#ffffff"
     bordercolor="#000000"
     borderopacity="0.25"
     inkscape:showpageshadow="2"
     inkscape:pageopacity="0.0"
     inkscape:pagecheckerboard="0"
     inkscape:deskcolor="#d1d1d1"
     inkscape:document-units="mm"
     showgrid="true"
     inkscape:zoom="1"
     inkscape:cx="286"
     inkscape:cy="157"
     inkscape:window-width="1920"
     inkscape:window-height="1017"
     inkscape:window-x="1912"
     inkscape:window-y="-8"
     inkscape:window-maximized="1"
     inkscape:current-layer="layer1"><inkscape:grid
       type="xygrid"
       id="grid107586" /></sodipodi:namedview><defs
     id="defs2"><linearGradient
       id="linearGradient10077"
       inkscape:swatch="solid"><stop
         style="stop-color:#ffffff;stop-opacity:0.99215686;"
         offset="0"
         id="stop10075" /></linearGradient><filter
       inkscape:collect="always"
       style="color-interpolation-filters:sRGB"
       id="filter4555-7"
       x="-1.0552142e-05"
       width="1.0000211"
       y="-0.00010639225"
       height="1.0002128"><feGaussianBlur
         inkscape:collect="always"
         stdDeviation="0.0011536701"
         id="feGaussianBlur4557-9" /></filter><style
       type="text/css"
       id="style2852">
   <![CDATA[
    .str0 {stroke:#1F1A17;stroke-width:0.374252}
    .str2 {stroke:#1F1A17;stroke-width:0.374252;stroke-linecap:round}
    .str1 {stroke:#1F1A17;stroke-width:0.374252;stroke-dasharray:1.871260 1.122756}
    .str4 {stroke:#1F1A17;stroke-width:0.374252;stroke-linecap:round;stroke-dasharray:1.871260 1.122756}
    .str3 {stroke:#1F1A17;stroke-width:0.374252;stroke-linecap:round;stroke-dasharray:1.871260 1.871260}
    .fil0 {fill:none}
    .fil1 {fill:#1F1A17;fill-rule:nonzero}
   ]]>
  </style><style
       id="style23281">.cls-1{fill:none;}</style><filter
       inkscape:collect="always"
       style="color-interpolation-filters:sRGB"
       id="filter4543"
       x="-1.0185023e-05"
       width="1.0000204"
       y="-1.4972854e-05"
       height="1.0000299"><feGaussianBlur
         inkscape:collect="always"
         stdDeviation="7.2992661e-05"
         id="feGaussianBlur4545" /></filter></defs><g
     inkscape:label="Capa 1"
     inkscape:groupmode="layer"
     id="layer1"
     transform="translate(-85.227387,-90.921029)"><g
       id="Capa_10_copia"
       transform="matrix(0.26458333,0,0,0.26458333,-8.7084723,51.930251)">
	</g><path
       id="rect3318-5"
       style="fill:#ebeeef;fill-opacity:1;stroke-width:0.44144"
       d="m 85.814304,159.47418 h 76.634916 v 30.63705 H 85.814304 Z" /><path
       id="rect3320-1"
       style="fill:#003c89;fill-opacity:1;stroke-width:1.00014;filter:url(#filter4555-7)"
       d="M 3.9756525,175.42555 H 266.36866 v 26.02453 H 3.9756525 Z"
       transform="matrix(0.29206216,0,0,0.25016633,84.653018,115.5886)" /><text
       xml:space="preserve"
       style="font-size:4.61949px;line-height:1.25;font-family:sans-serif;fill:#ffffff;stroke-width:0.28872"
       x="112.64575"
       y="165.94705"
       id="text294-5"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         id="tspan292-7"
         x="112.64575"
         y="165.94705"
         style="font-size:3.46461px;fill:#ffffff;stroke-width:0.28872">Información</tspan></text><text
       xml:space="preserve"
       style="font-size:4.79112px;line-height:1.25;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.299447"
       x="88.905998"
       y="177.71822"
       id="text294-2-5"
       transform="scale(1.0491626,0.95314111)"><tspan
         sodipodi:role="line"
         id="tspan292-1-99"
         x="88.905998"
         y="177.71822"
         style="font-size:3.59333px;fill:#232626;fill-opacity:1;stroke-width:0.299447"><tspan
           style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.299447"
           id="tspan478-4">ID Disp. :</tspan></tspan></text><text
       xml:space="preserve"
       style="font-size:4.61949px;line-height:1.25;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.28872"
       x="96.636139"
       y="176.75664"
       id="text294-2-2-4-3"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         id="tspan292-1-1-9-9"
         x="84.636139"
         y="176.75664"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.28872">Alias :</tspan></text><text
       xml:space="preserve"
       style="font-size:4.61949px;line-height:1.25;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.28872"
       x="90.514687"
       y="180.34782"
       id="text294-2-0-10"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         id="tspan292-1-9-2"
         x="90.514687"
         y="180.34782"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.28872">Ubicación :</tspan></text><text
       xml:space="preserve"
       style="font-size:4.58558px;line-height:0.85;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.286601"
       x="84.874893"
       y="183.35675"
       id="text294-2-8-2"
       transform="scale(1.0041531,0.99586407)"><tspan
         sodipodi:role="line"
         x="84.874893"
         y="183.35675"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.286601"
         id="tspan380-3">  Ult. medición :</tspan></text><text
       xml:space="preserve"
       style="font-size:4.07968px;line-height:1.25;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.28872"
       x="111.78088"
       y="171.38197"
       id="text294-2-02-5"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         id="txt-device"
         x="111.78088"
         y="171.38197"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.28872">00000000</tspan></text><text
       xml:space="preserve"
       style="font-size:4.61949px;line-height:0.85;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.28872"
       x="92.910698"
       y="189.25705"
       id="text294-2-8-9-5"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         x="92.910698"
         y="189.25705"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.28872"
         id="tspan380-4-4">  Pulsos  :</tspan></text><text
       xml:space="preserve"
       style="font-size:4.61949px;line-height:1.25;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.28872"
       x="112.16678"
       y="175.80244"
       id="text294-2-2-6-2"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         id="txt-Alias"
         x="99.16678"
         y="175.80244"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.28872">-</tspan></text><text
       xml:space="preserve"
       style="font-size:4.61949px;line-height:1.25;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.28872"
       x="111.78037"
       y="180.34782"
       id="text294-2-0-1-5"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         id="txt-ubicacion"
         x="111.78037"
         y="180.34782"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.28872">0,0</tspan></text><text
       xml:space="preserve"
       style="font-size:4.61949px;line-height:0.85;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.28872"
       x="111.7187"
       y="184.71269"
       id="text294-2-8-0-0"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         x="111.7187"
         y="184.71269"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.28872"
         id="txt-UltimaAct">00/00/00   00:00:00</tspan></text><text
       xml:space="preserve"
       style="font-size:4.61949px;line-height:0.85;font-family:sans-serif;fill:#ffffff;stroke-width:0.28872"
       x="110.80153"
       y="189.07758"
       id="text294-2-8-9-7-3"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         x="110.80153"
         y="189.07758"
         style="font-size:4.07968px;fill:#1a1a1a;stroke-width:0.28872"
         id="tspan380-4-8-5">  </tspan></text><text
       xml:space="preserve"
       style="font-size:4.61949px;line-height:0.85;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.28872"
       x="111.91395"
       y="189.43652"
       id="text294-2-8-9-2-9"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         x="111.91395"
         y="189.43652"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.28872"
         id="txt-Pulsos">0</tspan></text><g
       id="Capa_x0020_1"
       inkscape:label="Capa 1"
       inkscape:groupmode="layer"
       transform="matrix(0.23517337,0,0,0.25639214,157.04571,70.316583)"
       style="clip-rule:evenodd;fill-rule:evenodd;image-rendering:optimizeQuality;shape-rendering:geometricPrecision;text-rendering:geometricPrecision">
  <metadata
   id="CorelCorpID_0Corel-Layer" />  
 <g
   data-name="Layer 2"
   id="Layer_2"
   transform="matrix(1.1250565,0,0,1.0192143,-35.784344,98.528906)"
   style="display:inline;fill:#c83737;fill-opacity:0.9;stroke-width:1.00021;stroke-dasharray:none"><g
     id="S-Fraude"
     style="display:none;fill:#c83737;fill-opacity:0.9;stroke-width:1.00021;stroke-dasharray:none"><path
       d="M 22.7,28 H 9.3 a 6.25,6.25 0 0 1 -5.47,-3.15 6.15,6.15 0 0 1 0,-6.22 L 10.56,7.12 a 6.3,6.3 0 0 1 10.88,0 l 6.71,11.51 v 0 a 6.15,6.15 0 0 1 0,6.22 A 6.25,6.25 0 0 1 22.7,28 Z M 16,6 A 4.24,4.24 0 0 0 12.29,8.12 L 5.58,19.64 a 4.15,4.15 0 0 0 0,4.21 A 4.23,4.23 0 0 0 9.3,26 h 13.4 a 4.23,4.23 0 0 0 3.73,-2.15 4.15,4.15 0 0 0 0,-4.21 L 19.71,8.12 A 4.24,4.24 0 0 0 16,6 Z"
       id="path23287"
       style="fill:#c83737;fill-opacity:0.9;stroke-width:1.00021;stroke-dasharray:none" /><path
       class="cls-1"
       d="m 16,12 a 0.54,0.54 0 0 0 -0.44,0.22 0.52,0.52 0 0 0 -0.1,0.48 L 16,14.88 16.54,12.7 A 0.52,0.52 0 0 0 16.44,12.22 0.54,0.54 0 0 0 16,12 Z"
       id="path23289"
       style="fill:#c83737;fill-opacity:0.9;stroke-width:1.00021;stroke-dasharray:none" /><path
       d="m 18,11 a 2.56,2.56 0 0 0 -4,0 2.5,2.5 0 0 0 -0.46,2.19 L 15,19.24 a 1,1 0 0 0 1.94,0 l 1.51,-6.06 A 2.5,2.5 0 0 0 18,11 Z M 16.54,12.7 16,14.88 15.46,12.7 a 0.52,0.52 0 0 1 0.1,-0.48 0.55,0.55 0 0 1 0.88,0 0.52,0.52 0 0 1 0.1,0.48 z"
       id="path23291"
       style="fill:#c83737;fill-opacity:0.9;stroke-width:1.00021;stroke-dasharray:none" /><circle
       cx="16"
       cy="22.5"
       r="1.5"
       id="circle23293"
       style="fill:#c83737;fill-opacity:0.9;stroke-width:1.00021;stroke-dasharray:none" /></g></g><g
   id="frame"
   transform="matrix(0.91053163,0,0,0.83850055,-90.100031,336.92569)"><rect
     class="cls-1"
     height="32"
     width="32"
     id="rect23296"
     x="0"
     y="0" /></g><polygon
   style="display:inline;fill:#e6e6e6;fill-opacity:1"
   points="32,8.818 11.941,28.877 0,16.935 5.695,11.24 11.941,17.486 26.305,3.123 "
   id="checkConnect-5"
   transform="matrix(1.0071785,0,0,1.0856859,-26.521454,426.97583)" /></g><path
       d="m 151.74312,145.401 c -0.0815,0.0339 -0.14891,0.1433 -0.14891,0.24537 v 0.0898 l -0.80834,0.009 c -0.77571,0.009 -0.81532,0.0139 -0.94109,0.0631 -0.24464,0.10209 -0.41233,0.30116 -0.51016,0.60238 -0.0441,0.14096 -0.0466,0.22105 -0.0466,4.15132 0,3.93029 9.3e-4,4.0104 0.0466,4.15134 0.0977,0.30116 0.26552,0.50035 0.51016,0.60238 l 0.13043,0.0558 h 2.56239 2.5624 l 0.13281,-0.0558 c 0.1864,-0.0777 0.3494,-0.2405 0.44256,-0.43726 l 0.0768,-0.16273 0.007,-4.10272 c 0.005,-4.53026 0.0153,-4.23148 -0.13993,-4.4744 -0.0417,-0.0654 -0.12805,-0.15786 -0.19335,-0.20888 -0.21433,-0.16764 -0.23991,-0.1725 -1.06222,-0.1846 l -0.73847,-0.009 -0.0119,-0.10935 c -0.007,-0.0753 -0.03,-0.1311 -0.0724,-0.17492 l -0.0603,-0.0632 -0.84558,-0.006 c -0.46357,-8.9e-4 -0.86657,0.006 -0.89221,0.017 z m 3.35209,0.81132 c 0.0908,0.0387 0.21897,0.18462 0.24227,0.27693 0.0236,0.0851 0.0236,8.06215 0,8.14716 -0.0234,0.0922 -0.15128,0.23805 -0.24227,0.27692 -0.11195,0.0511 -5.00365,0.0511 -5.11551,0 -0.10023,-0.041 -0.21662,-0.18457 -0.24693,-0.2988 -0.0346,-0.12864 -0.0346,-7.9747 0,-8.10341 0.028,-0.1115 0.14688,-0.25505 0.2423,-0.2988 0.10448,-0.0484 5.01065,-0.0484 5.12014,0 z"
       id="Battery_Level"
       style="display:inline;opacity:0.906452;fill:#000000;fill-opacity:1;stroke:none;stroke-width:1.73255;stroke-miterlimit:4;stroke-dasharray:none" /><path
       d="m 150.30153,147.43493 v 0.50693 h 2.20831 2.20826 v -0.50693 -0.50696 h -2.20826 -2.20831 z"
       id="bat_L5"
       style="display:inline;opacity:0.906452;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.00712524;paint-order:stroke fill markers" /><path
       d="m 150.30153,149.01077 v 0.50696 h 2.20831 2.20826 v -0.50696 -0.50695 h -2.20826 -2.20831 z"
       id="bat_L4"
       style="display:inline;opacity:0.906452;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.0071252" /><path
       d="m 150.30153,150.51812 v 0.50693 h 2.20831 2.20826 v -0.50693 -0.50694 h -2.20826 -2.20831 z"
       id="bat_L3"
       style="display:inline;opacity:0.906452;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.00712529" /><path
       d="m 150.30153,152.09401 v 0.50693 h 2.20831 2.20826 v -0.50693 -0.50694 h -2.20826 -2.20831 z"
       id="bat_L2"
       style="display:inline;opacity:0.906452;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.00712524" /><path
       d="m 150.30153,153.66988 v 0.50694 h 2.20831 2.20826 v -0.50694 -0.50693 h -2.20826 -2.20831 z"
       id="bat_L1"
       style="display:inline;opacity:0.906452;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.00712532" /><g
       id="g76451"
       style="display:inline;fill:#1a1a1a;fill-opacity:1;stroke-width:12.3402;stroke-dasharray:none"
       transform="matrix(0.30487124,0,0,0.321627,80.093256,257.86033)"><g
         id="sFraude"
         style="fill:#1a1a1a"><g
           id="g27831"
           style="fill:#1a1a1a"><g
             id="g31956"
             transform="matrix(0.07298313,0,0,0.06903844,38.558924,-350.55249)"
             style="fill:#1a1a1a"><g
               id="g31865"
               style="fill:#1a1a1a">
	<g
   id="g31863"
   style="fill:#1a1a1a">
		<path
   d="M 394.095,330.155 C 374.315,290.586 373.76,249.848 373.76,249.447 v -46.08 c 0,-64.93 -52.83,-117.76 -117.76,-117.76 -64.93,0 -117.76,52.83 -117.76,117.76 v 46.08 c 0,0.333 -0.478,40.986 -20.335,80.708 -3.968,7.936 -3.541,17.357 1.118,24.909 4.668,7.552 12.902,12.143 21.777,12.143 h 65.809 c -1.109,4.087 -1.809,8.354 -1.809,12.8 0,28.237 22.963,51.2 51.2,51.2 28.237,0 51.2,-22.963 51.2,-51.2 0,-4.446 -0.7,-8.713 -1.809,-12.8 H 371.2 c 8.875,0 17.109,-4.591 21.777,-12.143 4.668,-7.544 5.086,-16.973 1.118,-24.909 z M 256,405.606 c -14.114,0 -25.6,-11.486 -25.6,-25.6 0,-4.762 1.664,-8.977 3.934,-12.8 h 43.332 c 2.27,3.823 3.934,8.038 3.934,12.8 0,14.115 -11.486,25.6 -25.6,25.6 z m -115.2,-64 c 23.04,-46.08 23.04,-92.16 23.04,-92.16 v -46.08 c 0,-50.901 41.259,-92.16 92.16,-92.16 50.901,0 92.16,41.259 92.16,92.16 v 46.08 c 0,0 0,46.08 23.04,92.16 -23.04,0 -207.36,0 -230.4,0 z"
   id="path31861"
   style="fill:#1a1a1a" />
	</g>
</g><g
               id="g31871"
               style="fill:#1a1a1a">
	<g
   id="g31869"
   style="fill:#1a1a1a">
		<path
   d="m 428.809,108.16 -21.598,13.679 c 17.63,27.733 27.989,60.527 27.989,95.761 0,35.234 -10.359,68.028 -27.998,95.761 L 428.8,327.04 c 20.156,-31.701 32,-69.18 32,-109.44 0,-40.26 -11.844,-77.739 -31.991,-109.44 z"
   id="path31867"
   style="fill:#1a1a1a" />
	</g>
</g><g
               id="g31877"
               style="fill:#1a1a1a">
	<g
   id="g31875"
   style="fill:#1a1a1a">
		<path
   d="M 104.798,121.839 83.191,108.16 C 63.044,139.861 51.2,177.34 51.2,217.6 c 0,40.26 11.844,77.739 31.991,109.44 l 21.598,-13.679 C 87.159,285.628 76.8,252.834 76.8,217.6 c 0,-35.234 10.359,-68.028 27.998,-95.761 z"
   id="path31873"
   style="fill:#1a1a1a" />
	</g>
</g><g
               id="g31883"
               style="fill:#1a1a1a">
	<g
   id="g31881"
   style="fill:#1a1a1a">
		<path
   d="m 472.013,80.794 -21.606,13.679 c 22.664,35.669 35.993,77.832 35.993,123.127 0,45.295 -13.329,87.458 -35.994,123.127 l 21.606,13.679 C 497.195,314.778 512,267.93 512,217.6 512,167.27 497.195,120.422 472.013,80.794 Z"
   id="path31879"
   style="fill:#1a1a1a" />
	</g>
</g><g
               id="g31889"
               style="fill:#1a1a1a">
	<g
   id="g31887"
   style="fill:#1a1a1a">
		<path
   d="M 61.594,94.473 39.987,80.794 C 14.805,120.422 0,167.27 0,217.6 0,267.93 14.805,314.778 39.987,354.406 L 61.593,340.727 C 38.929,305.058 25.6,262.895 25.6,217.6 25.6,172.305 38.929,130.142 61.594,94.473 Z"
   id="path31885"
   style="fill:#1a1a1a" />
	</g>
</g><g
               id="g31891"
               style="fill:#1a1a1a">
</g><g
               id="g31893"
               style="fill:#1a1a1a">
</g><g
               id="g31895"
               style="fill:#1a1a1a">
</g><g
               id="g31897"
               style="fill:#1a1a1a">
</g><g
               id="g31899"
               style="fill:#1a1a1a">
</g><g
               id="g31901"
               style="fill:#1a1a1a">
</g><g
               id="g31903"
               style="fill:#1a1a1a">
</g><g
               id="g31905"
               style="fill:#1a1a1a">
</g><g
               id="g31907"
               style="fill:#1a1a1a">
</g><g
               id="g31909"
               style="fill:#1a1a1a">
</g><g
               id="g31911"
               style="fill:#1a1a1a">
</g><g
               id="g31913"
               style="fill:#1a1a1a">
</g><g
               id="g31915"
               style="fill:#1a1a1a">
</g><g
               id="g31917"
               style="fill:#1a1a1a">
</g><g
               id="g31919"
               style="fill:#1a1a1a">
</g></g></g></g></g><polygon
       style="display:none;fill:#00ff00;fill-opacity:1"
       points="26.305,3.123 32,8.818 11.941,28.877 0,16.935 5.695,11.24 11.941,17.486 "
       id="checkConnect"
       transform="matrix(0.23686156,0,0,0.27836132,150.56012,179.47822)" /><path
       id="rect1327"
       style="fill:#98b5dc;fill-opacity:1;filter:url(#filter4543)"
       d="m 243,119.6 h 27.7 v 11.7 H 243 Z"
       transform="matrix(0.44048054,0,0,0.44969573,-16.968285,57.54)" /><path
       fill="#006fa2"
       d="m 250.7,117.3 c 0,-1.9 -1.6,-3.5 -3.5,-3.5 H 237 c -1.9,0 -3.5,1.6 -3.5,3.5 v 14 h 17.2 z"
       id="path1329"
       style="fill:#e0dff4;fill-opacity:1;filter:url(#filter4543)"
       transform="matrix(0.45208477,0,0,0.44969573,-19.877467,57.54)" /><path
       fill="#006fa2"
       d="m 237,149.5 h 10.3 c 1.9,0 3.5,-1.6 3.5,-3.5 V 131.3 H 233.6 V 146 c -0.1,1.9 1.5,3.5 3.4,3.5 z"
       id="path1333"
       style="filter:url(#filter4543)"
       transform="matrix(0.44048054,0,0,0.44969573,-16.968285,57.54)" /><path
       id="rect1337"
       style="fill:#326cb9;fill-opacity:1;filter:url(#filter4543)"
       d="m 243,131.3 h 27.7 v 12.3 H 243 Z"
       transform="matrix(0.44048054,0,0,0.44969573,-16.968285,57.54)" /><path
       id="rect1341"
       style="fill:#98b5dc;fill-opacity:1;stroke-width:0.445064"
       d="m 145.52497,111.32361 h 12.20131 v 5.26144 h -12.20131 z" /><path
       fill="#006fa2"
       d="m 154.16025,110.2893 c 0,-0.85442 0.72426,-1.57393 1.5843,-1.57393 h 4.66239 c 0.86006,0 1.58432,0.71951 1.58432,1.57393 v 6.29574 h -7.78574 v -6.29574 z"
       id="path1343"
       style="fill:#e0dff4;fill-opacity:1;stroke-width:0.451175" /><path
       fill="#006fa2"
       d="m 160.36917,124.76951 h -4.53695 c -0.83692,0 -1.54169,-0.71952 -1.54169,-1.57394 v -6.61053 h 7.57627 v 6.61053 c 0.044,0.85442 -0.61667,1.57394 -1.49763,1.57394 z"
       id="path1347"
       style="stroke-width:0.445064" /><path
       id="rect1351"
       style="opacity:0.975078;fill:#326cb9;fill-opacity:1;stroke-width:0.445064"
       d="m 145.52497,116.58505 h 12.20131 v 5.53125 h -12.20131 z" /><path
       fill="#000050"
       d="m 123.94143,92.886081 c -12.81799,0 -23.16927,10.612819 -23.16927,23.653999 0,13.04116 10.39533,23.654 23.16927,23.654 12.81798,0 23.16927,-10.61284 23.16927,-23.654 0,-13.04118 -10.39534,-23.653999 -23.16927,-23.653999 z m 0,44.834669 c -11.45249,0 -20.74662,-9.48858 -20.74662,-21.18067 0,-11.6921 9.29413,-21.180669 20.74662,-21.180669 11.45248,0 20.74663,9.488569 20.74663,21.180669 -0.0441,11.69209 -9.33819,21.18067 -20.74663,21.18067 z"
       id="path1355"
       style="fill:#003c89;fill-opacity:1;stroke-width:0.445064" /><path
       id="circle1357"
       style="fill:#006fa2;stroke-width:0.445064"
       d="m 144.68805,116.54008 a 20.746631,21.180667 0 0 1 -20.74663,21.18067 20.746631,21.180667 0 0 1 -20.74661,-21.18067 20.746631,21.180667 0 0 1 20.74661,-21.180669 20.746631,21.180667 0 0 1 20.74663,21.180669 z" /><path
       id="circle1361"
       style="fill:#55d3f3;fill-opacity:1;stroke-width:0.445064"
       d="m 144.68805,116.54008 a 20.746631,21.180667 0 0 1 -20.74663,21.18067 20.746631,21.180667 0 0 1 -20.74661,-21.18067 20.746631,21.180667 0 0 1 20.74661,-21.180669 20.746631,21.180667 0 0 1 20.74663,21.180669 z" /><path
       fill="#006fa2"
       d="m 118.08304,139.42959 c 1.85002,0.49466 3.83217,0.76449 5.81434,0.76449 1.93811,0 3.78813,-0.22485 5.5941,-0.71952 -1.62977,-0.40473 -3.65599,-0.62958 -5.81434,-0.62958 -2.07027,0 -3.96433,0.22485 -5.5941,0.58461 z"
       id="path1363"
       style="fill:#027693;fill-opacity:1;stroke-width:0.445064" /><path
       fill="#e5f5fd"
       d="m 123.99847,99.73329 c -5.75119,0 -10.7713,3.07275 -13.54943,7.70627 v 10.34006 h 27.09887 v -10.34006 0 c -2.72938,-4.63352 -7.74951,-7.70627 -13.54944,-7.70627 z"
       id="path1367"
       style="display:inline;fill:#f8fafa;fill-opacity:1;stroke-width:0.487564" /><path
       id="rect1369"
       style="fill:#027693;fill-opacity:1;stroke-width:0.613316"
       d="m 110.46216,109.44864 h 27.06767 v 5.27933 h -27.06767 z" /><path
       id="rect1371"
       style="display:inline;fill:#003c89;fill-opacity:1;stroke-width:0.613316"
       d="m 111.32788,110.36112 h 2.42397 v 3.45438 h -2.42397 z" /><path
       id="rect1373"
       style="fill:#003c89;fill-opacity:1;stroke-width:0.613316"
       d="m 114.55983,110.36112 h 2.42397 v 3.45438 h -2.42397 z" /><path
       id="rect1375"
       style="fill:#003c89;fill-opacity:1;stroke-width:0.613316"
       d="m 117.84953,110.36112 h 2.42397 v 3.45438 h -2.42397 z" /><path
       id="rect1377"
       style="fill:#003c89;fill-opacity:1;stroke-width:0.613316"
       d="m 121.13917,110.36112 h 2.42396 v 3.45438 h -2.42396 z" /><path
       id="rect1379"
       style="fill:#003c89;fill-opacity:1;stroke-width:0.613316"
       d="m 124.42882,110.36112 h 2.42397 v 3.45438 h -2.42397 z" /><path
       id="rect1385"
       style="fill:#cf2c03;fill-opacity:1;stroke-width:0.613316"
       d="m 127.71852,110.36112 h 2.42395 v 3.45438 h -2.42395 z" /><path
       id="rect1391"
       style="fill:#cf2c03;fill-opacity:1;stroke-width:0.613316"
       d="m 131.00819,110.36112 h 2.42395 v 3.45438 h -2.42395 z" /><path
       id="rect1397"
       style="fill:#cf2c03;fill-opacity:1;stroke-width:0.613316"
       d="m 134.29787,110.36112 h 2.42396 v 3.45438 h -2.42396 z" /><path
       id="rect1403"
       style="fill:#003c89;fill-opacity:1;stroke-width:0.487564"
       d="m 116.63297,104.16169 h 14.47547 v 1.60953 h -14.47547 z" /><path
       id="circle1405"
       style="fill:#ebeeef;fill-opacity:1;stroke-width:0.475123"
       d="m 143.90707,119.58136 a 5.2997308,5.3431251 0 0 1 -5.29972,5.34313 5.2997308,5.3431251 0 0 1 -5.29974,-5.34313 5.2997308,5.3431251 0 0 1 5.29974,-5.34312 5.2997308,5.3431251 0 0 1 5.29972,5.34312 z" /><path
       fill="#006fa2"
       d="m 140.42349,119.53549 c 0,0.9267 -0.73107,1.6583 -1.65711,1.6583 -0.92605,0 -1.65714,-0.7316 -1.65714,-1.6583 0,-0.92671 0.73109,-1.65832 1.65714,-1.65832 0.92604,0 1.65711,0.73161 1.65711,1.65832 z"
       id="path1407"
       style="fill:#7c1a02;fill-opacity:1;stroke-width:0.487564" /><path
       id="polyline1409"
       style="fill:#7c1a02;fill-opacity:1;stroke-width:0.487564"
       d="m 138.91259,118.41369 2.29073,-1.60955 -1.31596,2.43869" /><path
       fill="#17154d"
       d="m 126.80456,129.62621 v -1.03428 h -1.40954 c -0.0441,-0.09 -0.0881,-0.17988 -0.13214,-0.26982 l 1.0131,-1.0343 -0.70477,-0.71952 -1.0131,1.0343 c -0.0881,-0.045 -0.1762,-0.09 -0.2643,-0.09 v -1.48401 h -1.0131 v 1.48401 c -0.0881,0.045 -0.17619,0.045 -0.26428,0.09 l -1.01311,-1.0343 -0.70477,0.71952 1.0131,1.0343 c -0.0441,0.09 -0.0881,0.17987 -0.13213,0.26982 h -1.40955 v 1.03428 h 1.36549 c 0.0441,0.09 0.0881,0.17988 0.13215,0.3148 l -0.96906,0.98932 0.70477,0.71952 0.96906,-0.98933 c 0.0881,0.045 0.17618,0.09 0.30833,0.13491 v 1.39406 h 1.0131 v -1.39406 c 0.0881,-0.045 0.22025,-0.09 0.30834,-0.13491 l 0.96906,0.98933 0.70477,-0.71952 -0.96906,-0.98932 c 0.0441,-0.09 0.0881,-0.17988 0.13214,-0.3148 z"
       id="path1411"
       style="fill:#7c1a02;fill-opacity:1;stroke-width:0.445064" /><path
       id="rect1413"
       style="fill:#003c89;fill-opacity:1;stroke-width:0.445064"
       d="m 108.70081,120.56758 h 5.90244 v 2.02364 h -5.90244 z" /><path
       id="rect1415"
       style="fill:#003c89;fill-opacity:1;stroke-width:0.445064"
       d="m 108.70081,125.26418 h 10.87986 v 2.02363 h -10.87986 z" /><path
       fill="#01a5f0"
       d="m 87.249414,124.90443 h 4.625043 c 0.880964,0 1.585733,-0.76886 1.585733,-1.72993 v -7.25599 h -7.796508 v 7.25599 c 0,0.96107 0.704768,1.72993 1.585732,1.72993 z"
       id="path1427"
       style="fill:#c2c0e9;fill-opacity:1;stroke-width:0.46007" /><path
       fill="#01a5f0"
       d="m 155.78817,124.90443 h 4.62505 c 0.88095,0 1.58572,-0.79466 1.58572,-1.78798 v -7.49951 h -7.7965 v 7.49951 c 0,0.99332 0.70477,1.78798 1.58573,1.78798 z"
       id="path1435"
       style="fill:#c2c0e9;fill-opacity:1;stroke-width:0.467726" /><path
       id="rect2864-3"
       style="font-size:21px;fill:#006fa2;stroke-width:0.308479"
       d="m 104.89024,110.052 h 3.9099 v 0.52131 h -3.9099 z"
       class="st4" /><path
       id="rect2866-2"
       style="font-size:21px;fill:#006fa2;stroke-width:0.308479"
       d="m 104.89024,111.27864 h 4.22021 v 0.52132 h -4.22021 z"
       class="st4" /><path
       id="rect2868-2"
       style="font-size:21px;fill:#006fa2;stroke-width:0.308479"
       d="m 104.89024,112.59727 h 3.3203 v 0.52132 h -3.3203 z"
       class="st4" /><path
       id="rect2874-1"
       style="font-size:18px;fill:#000050;stroke-width:0.400946"
       d="m 111.59644,115.29958 h 14.94748 v 0.17209 h -14.94748 z"
       class="st3" /><path
       id="rect2872-7"
       style="font-size:18px;fill:#000050;stroke-width:0.308479"
       d="m 111.44129,115.04233 h 0.15515 v 0.42934 h -0.15515 z"
       class="st3" /><path
       id="rect2872-3"
       style="font-size:18px;fill:#000050;stroke-width:0.308479"
       d="m 126.54392,115.04233 h 0.15516 v 0.42934 h -0.15516 z"
       class="st3" /><text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:4.12999px;line-height:1.25;font-family:sans-serif;fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.28159"
       x="134.72464"
       y="113.15085"
       id="L1_"
       transform="scale(0.99593092,1.0040857)"><tspan
         sodipodi:role="line"
         id="L1"
         x="134.72464"
         y="113.15085"
         style="font-size:4.12999px;fill:#ffffff;stroke-width:0.28159">0</tspan></text><text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:4.12999px;line-height:1.25;font-family:sans-serif;fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.28159"
       x="131.45514"
       y="113.10422"
       id="L2_"
       transform="scale(0.99593092,1.0040857)"><tspan
         sodipodi:role="line"
         id="L2"
         x="131.45514"
         y="113.10422"
         style="font-size:4.12999px;fill:#ffffff;stroke-width:0.28159">0</tspan></text><text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:4.12999px;line-height:1.25;font-family:sans-serif;fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.28159"
       x="128.1382"
       y="113.10422"
       id="L3_"
       transform="scale(0.99593092,1.0040857)"><tspan
         sodipodi:role="line"
         id="L3"
         x="128.1382"
         y="113.10422"
         style="font-size:4.12999px;fill:#ffffff;stroke-width:0.28159">0</tspan></text><text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:4.12999px;line-height:1.25;font-family:sans-serif;fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.28159"
       x="124.82916"
       y="113.10422"
       id="L4_"
       transform="scale(0.99593092,1.0040857)"><tspan
         sodipodi:role="line"
         id="L4"
         x="124.82916"
         y="113.10422"
         style="font-size:4.12999px;fill:#ffffff;stroke-width:0.28159">0</tspan></text><text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:4.12999px;line-height:1.25;font-family:sans-serif;fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.28159"
       x="121.535"
       y="113.10422"
       id="L5_"
       transform="scale(0.99593092,1.0040857)"><tspan
         sodipodi:role="line"
         id="L5"
         x="121.535"
         y="113.10422"
         style="font-size:4.12999px;fill:#ffffff;stroke-width:0.28159">0</tspan></text><text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:3.75453px;line-height:1.25;font-family:sans-serif;fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.28159"
       x="118.22182"
       y="113.10422"
       id="L6_"
       transform="scale(0.99593092,1.0040857)"><tspan
         sodipodi:role="line"
         id="L6"
         x="118.22182"
         y="113.10422"
         style="font-size:4.12999px;fill:#ffffff;stroke-width:0.28159">0</tspan></text><text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:4.12999px;line-height:1.25;font-family:sans-serif;fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.28159"
       x="114.92983"
       y="113.10422"
       id="L7_"
       transform="scale(0.99593092,1.0040857)"><tspan
         sodipodi:role="line"
         id="L7"
         x="114.92983"
         y="113.10422"
         style="font-size:4.12999px;fill:#ffffff;stroke-width:0.28159">0</tspan></text><text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:3.75453px;line-height:1.25;font-family:sans-serif;fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.28159"
       x="111.68378"
       y="113.10422"
       id="L8_"
       transform="scale(0.99593092,1.0040857)"><tspan
         sodipodi:role="line"
         id="L8"
         x="111.68378"
         y="113.10422"
         style="font-size:4.12999px;fill:#ffffff;stroke-width:0.28159">0</tspan></text><text
       xml:space="preserve"
       style="font-size:4.50544px;line-height:1.25;font-family:sans-serif;fill:#ffffff;stroke-width:0.28159"
       x="117.93118"
       y="116.88589"
       id="text294-25"
       transform="scale(0.99593092,1.0040857)"><tspan
         sodipodi:role="line"
         id="tspan292-2"
         x="117.93118"
         y="116.88589"
         style="font-size:2.25272px;fill:#000050;fill-opacity:1;stroke-width:0.28159">m3</tspan></text><text
       xml:space="preserve"
       style="font-size:1.41111px;line-height:0.95;font-family:sans-serif;fill:#ffffff;stroke-width:0.28159"
       x="115.97971"
       y="120.90353"
       id="text294-25-1"
       transform="scale(0.99593092,1.0040857)"><tspan
         sodipodi:role="line"
         id="tspan292-2-7"
         x="115.97971"
         y="120.90353"
         style="font-size:1.41111px;fill:#000050;fill-opacity:1;stroke-width:0.28159">XXXX IMP/M3</tspan><tspan
         sodipodi:role="line"
         x="115.97971"
         y="122.24409"
         style="font-size:1.41111px;fill:#000050;fill-opacity:1;stroke-width:0.28159"
         id="tspan3307">PULSE RC4 A  - TECA</tspan></text><text
       xml:space="preserve"
       style="font-size:1.41111px;line-height:0.95;font-family:sans-serif;fill:#ffffff;stroke-width:0.28159"
       x="133.46339"
       y="127.1032"
       id="text294-25-1-9"
       transform="scale(0.99593092,1.0040857)"><tspan
         sodipodi:role="line"
         x="133.46339"
         y="127.1032"
         style="font-size:1.41111px;fill:#000050;fill-opacity:1;stroke-width:0.28159"
         id="tspan4803">Q0: X</tspan><tspan
         sodipodi:role="line"
         x="133.46339"
         y="128.44376"
         style="font-size:1.41111px;fill:#000050;fill-opacity:1;stroke-width:0.28159"
         id="tspan4807">Q1 :X</tspan><tspan
         sodipodi:role="line"
         x="133.46339"
         y="129.7843"
         style="font-size:1.41111px;fill:#000050;fill-opacity:1;stroke-width:0.28159"
         id="tspan4809">Q2: X</tspan></text></g><style
     type="text/css"
     id="style11901">
	.st0{fill:#333435;}
	.st1{font-family:'MyriadPro-Regular';}
	.st2{font-size:15.1008px;}
	.st3{font-size:18px;}
	.st4{font-size:21px;}
	.st5{font-size:13.4951px;}
	.st6{font-size:12.1191px;}
	.st7{font-size:16.5828px;}
	.st8{font-size:9.9589px;}
	.st9{font-family:'Calculator';}
	.st10{font-size:45.1149px;}
	.st11{letter-spacing:4;}
	.st12{font-size:23px;}
	.st13{fill:#BABCBF;}
	.st14{fill:#A2A4A8;}
	.st15{fill:#202024;}
	.st16{fill:#D6D7D9;}
	.st17{fill:#BCBEBF;}
	.st18{fill:#4E4E53;}
	.st19{fill:#A84B49;}
	.st20{fill:#C1C1BD;}
	.st21{fill:#101012;}
	.st22{fill:#F7F7F7;}
	.st23{fill:#8B8D8F;}
	.st24{fill:#C7DA9D;}
	.st25{fill:#6D454A;}
	.st26{fill:#A35559;}
	.st27{fill:#282834;}
</style></svg>
        `,
    };
  },
  methods: {
    async showSvg(msg) {
      this.open = true;
      const { check, bateria, detalle, consumo, campana } =
        toSvg.toParseSvg(msg);
      consumo.forEach((element) => {
        document.getElementById(element.elementId).textContent =
          element.textContent;
      });
      detalle.forEach((element) => {
        document.getElementById(element.elementId).textContent =
          element.textContent;
      });
      bateria.forEach((element) => {
        document.getElementById(element.elementId).style.display =
          element.attributeValue;
      });
      campana.forEach((element) => {
        document.getElementById(element.elementId).style.display =
          element.attributeValue;
      });
      check.forEach((element) => {
        document.getElementById(element.elementId).style.display =
          element.attributeValue;
      });
      console.log(msg.porcentajeBateria);

      const levels = ["bat_L1", "bat_L2", "bat_L3", "bat_L4", "bat_L5"];

      for (let i = 0; i < levels.length; i++) {
        if (
          msg.porcentajeBateria > i * 20 &&
          msg.porcentajeBateria <= (i + 1) * 20
        ) {
          for (let j = i; j < levels.length; j++) {
            document.getElementById(levels[j]).style.display = "none";
          }
          break;
        }
      }
    },
    close() {
      this.open = false;
    },
  },
};
</script>

<style></style>
