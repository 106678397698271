<template>
  <div>
    <br />
    <v-card>
      <v-card-title>
        <v-card-title>
          <h5>Información del dispositivo</h5>
        </v-card-title>
      </v-card-title>
      <v-card-text>
        <v-col>
          <v-row>
            <v-col class="d-flex" cols="12" md="3" sm="6" xs="12">
              <v-select
                v-model="idUserN1Selected"
                :items="userN1"
                item-text="email"
                item-value="id"
                label="Nivel 1"
                outlined
                @change="changeUserN1()"
              ></v-select
            ></v-col>
            <v-col class="d-flex" cols="12" md="3" sm="6" xs="12">
              <v-select
                v-model="idUserN2Selected"
                :items="usersN2Computed"
                item-text="email"
                item-value="id"
                label="Nivel 2"
                outlined
                style="width: 250px"
                @change="changeUserN2()"
              ></v-select
            ></v-col>
            <v-col class="d-flex" cols="12" md="2" sm="6" xs="12">
              <v-select
                v-model="search_by"
                :items="items"
                item-text="id"
                item-value="value"
                label="Buscar por..."
                outlined
                @change="changeSearchSelected()"
              ></v-select
            ></v-col>
            <v-col class="d-flex" cols="12" md="2" sm="6" xs="12">
              <v-select
                v-model="idDeviceSelected"
                :items="devices"
                :item-text="search_by"
                item-value="id"
                label="De"
                size="large"
                outlined
                @change="changeDevice()"
              ></v-select>
            </v-col>
            <v-col class="d-flex" cols="12" md="2" sm="6" xs="12">
              <v-btn height="56px" width="100%" dark color="primary"
                >Buscar</v-btn
              >
            </v-col>
          </v-row>
          <EmptyItems
            v-if="!showDataDevice"
            icon="fa-solid fa-ethernet"
            text="No se encontró data del dispositivo. Seleccione un dispositivo"
          />
          <div>
            <WaterInfo :unidad="unidad" :service="service" ref="mostarInfo" />
          </div>
        </v-col>
      </v-card-text>
    </v-card>
    <br />
    <br />
    <v-card v-if="showDataDevice">
      <v-card-title>
        <v-card-title>
          <h5>Historial del consumo por periodo</h5>
        </v-card-title>
      </v-card-title>
      <v-card-text>
        <bar-chart
          :itemsConsumo="itemsConsumo"
          :deviceDataId="idDeviceSelected"
          :service="service"
          :unidad="unidad"
        ></bar-chart>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import BarChart from "./Components/water/BarChart.vue";
import EmptyItems from "@/components/common/EmptyItems.vue";
import WaterInfo from "./Components/water/WaterInfo.vue";
import BackendApi from "@/services/backend.service";
import moment from "moment";
export default {
  components: {
    BarChart,
    WaterInfo,
    EmptyItems,
  },
  data() {
    return {
      unidad: "m3",
      service: "agua",
      deviceUser: [],
      acumulado: 0,
      idDevice: 0,
      tendencia: 0,
      color: { hex: null },
      isBrandBlank: false,
      dialog: false,
      search_by: null,
      userN1: [],
      idUserN1Selected: null,
      userN2: [],
      idUserN2Selected: null,
      devices: [],
      idDeviceSelected: null,
      items: [
        {
          id: "Alias",
          value: "alias",
        },
        {
          id: "Id",
          value: "device_external_id",
        },
        {
          id: "Suministro",
          value: "suministro",
        },
      ],
      pulsosA: 4095,
      headers: [
        {
          text: "Id",
          value: "id",
        },
        { text: "Nombre", value: "name" },
        { text: "Email", value: "email" },
        { text: "RUC", value: "ruc" },
        { text: "Ult. Sesión", value: "last_sesion" },
        { text: "Acciones", value: "actions" },
      ],
      msg: {
        caConsumo: 9876543210,
        device: "CAFECAFE",
        alias: "DispositivoDeMedicionNumeroUno",
        ubicacion: "-12.76553733,-76.8755363",
        timestamp: 45677,
        pulsos: 4294967295,
        fraude: true,
        s_energia: true,
        status_ctlr: true,
        checkConnect: true,
        Status_batt: [2800, 3000, 4000],
      },
      device: null,
      showDataDevice: false,
      status_ctlr: null,
    };
  },
  created() {
    this.getUserN1();
  },
  computed: {
    itemsConsumo() {
      return [
        {
          id: 1,
          value: "Volumen " + this.unidad,
        },
        {
          id: 2,
          value: "Caudal " + this.unidad + "/h",
        },
      ];
    },
    isAdmin() {
      return (
        this.$cookies.get("isSuperAdmin") === "true" ||
        this.$cookies.get("isAdmin") === "true"
      );
    },
    isN1() {
      return this.$cookies.get("user").profileId === 3;
    },
    isN2() {
      return this.$cookies.get("user").profileId === 4;
    },
    usersN2Computed() {
      return this.userN2.length > 0 ? this.userN2 : [];
    },
  },
  methods: {
    saveTendencia(algo) {
      this.tendencia = algo;
    },
    getUserN1() {
      if (this.isAdmin) {
        BackendApi.get("/user/usersN1ForReport")
          .then((response) => {
            if (response.data.success) {
              this.userN1 = response.data.data;
            }
          })
          .catch(() => {});
      } else if (this.isN1) {
        const user = [
          {
            id: this.$cookies.get("user").id,
            email: this.$cookies.get("user").email,
          },
        ];
        this.userN1 = user;
      } else if (this.isN2) {
        BackendApi.get("/user/father")
          .then((response) => {
            if (response.data.success) {
              this.userN1 = response.data.data;
            }
          })
          .catch(() => {});
      }
    },
    getUserN2() {
      if (!this.isN2) {
        BackendApi.get("/user/usersN2ForReport/" + this.idUserN1Selected)
          .then((response) => {
            if (response.data.success) {
              this.userN2 = response.data.data;
            }
          })
          .catch(() => {});
      } else {
        const user = [
          {
            id: this.$cookies.get("user").id,
            email: this.$cookies.get("user").email,
          },
        ];
        this.userN2 = user;
      }
    },
    usersN2ForReport() {
      this.devices = [];
      BackendApi.get("/device/deviceRelatedToUserN2/" + this.idUserN2Selected)
        .then((response) => {
          if (response.data.success) {
            this.devices = response.data.data.filter(
              (device) => device.device_product.deviceClassId === 1
            );
          }
        })
        .catch(() => {});
    },
    getDataDevice() {
      BackendApi.get("/deviceData/lastDeviceData/" + this.idDeviceSelected)
        .then((response) => {
          if (response.data.success) {
            this.msg = response.data.data;
            this.msg.checkConnect2 =
              moment(new Date()).diff(
                moment(response.data.lastDeviceData.createdAt),
                "hours"
              ) < 24
                ? true
                : false;
            console.log(
              moment(new Date()).diff(
                moment(response.data.lastDeviceData.createdAt),
                "hours"
              )
            );
            console.log(this.msg.checkConnect2);
            this.msg.device = this.device.device_external_id;
            this.msg.alias = this.device.alias;
            this.msg.caConsumo =
              (response.data.data.value_in_momment + this.device.calibrar) *
              1000;
            this.msg.checkConnect = response.data.checkConnect;
            this.msg.sFraude = response.data.data.fraude;
            // this.msg.s_energia = response.data.data.bateria;
            this.msg.bateria = response.data.data.bateria;
            this.msg.porcentajeBateria = response.data.data.porcentaje;
            this.msg.s_energia = false;
            this.msg.ubicacion =
              Number(this.device.latitud).toFixed(5) +
              "," +
              Number(this.device.longitud).toFixed(5);
            this.msg.UMedicion = moment(response.data.data.createdAt).format(
              "YYYY-MM-DD hh:mm:ss"
            );

            this.status_ctlr = response.data.data.status_ctlr;

            this.showDataDevice = true;
            setTimeout(() => {
              this.$refs.mostarInfo.search(this.msg, this.status_ctlr);
            }, 4);
          }

          this.$refs.mostarInfo.getDate(this.idDeviceSelected);
          this.$refs.mostarInfo.mostrarDatos(
            this.device,
            this.idDeviceSelected
          );
        })
        .catch(() => {
          this.showDataDevice = false;
          this.$refs.mostarInfo.cerrarDatos();
        });
    },
    changeUserN1() {
      this.getUserN2();
      this.idUserN2Selected = null;
      this.search_by = null;
      this.idDeviceSelected = null;
    },
    changeUserN2() {
      this.search_by = null;
      this.idDeviceSelected = null;
    },
    changeSearchSelected() {
      this.usersN2ForReport();
    },
    changeDevice() {
      this.device = this.devices.find(
        (device) => device.id === this.idDeviceSelected
      );
      this.deviceUser = this.device;

      this.getDataDevice();
    },
  },
};
</script>

<style scoped>
.actuadorActive {
  width: 14px;
  height: 14px;
  background: rgb(24, 255, 24);
  border-radius: 50%;
}

.actuadorNoActive {
  width: 14px;
  height: 14px;
  background: rgb(251, 25, 25);
  border-radius: 50%;
}

/* .svg-size {
  width: 100px;
  border: 1px solid black;
} */

/**asas
energia
import EnergiaSvgMedicion from "./../../../components/svg/energia/EnergiaSvgMedicion.vue";
import EnergiaSvgTelecontrol from "./../../../components/svg/energia/EnergiaSvgTelecontrol.vue";
import EnergiaSvgMedicionTelecontrol from "./../../../components/svg/energia/EnergiaSvgMedicionTelecontrol.vue";

gas 

import GasSvgMedicion from "./../../../components/svg/gas/GasSvgMedicion.vue";
import GasSvgTelecontrol from "./../../../components/svg/gas/GasSvgTelecontrol.vue";
import GasSvgMedicionTelecontrol from "./../../../components/svg/gas/GasSvgMedicionTelecontrol.vue";

 */
</style>
