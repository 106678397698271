<template>
  <div class="card-padding">
    <v-form
      lazy-validation
      ref="formBarChart"
      @submit.prevent="searchDeviceData"
    >
      <v-row>
        <v-col cols="3">
          <v-select
            v-model="typeConsumo"
            :items="itemsConsumo"
            item-text="value"
            item-value="id"
            clas="py-0"
            dense
            label="Tipo consumo"
            :rules="[(v) => !!v || 'El  tipo de filtro es obligatorio']"
            outlined
            @change="changeTypesOfSearch()"
          ></v-select
        ></v-col>
        <v-col cols="3">
          <v-select
            v-model="typeOfSearch"
            :items="typesOfSearch"
            item-text="value"
            item-value="id"
            clas="py-0"
            dense
            label="Tipo de filtro"
            :rules="[(v) => !!v || 'El  tipo de filtro es obligatorio']"
            outlined
            @change="changeTypesOfSearch()"
          ></v-select
        ></v-col>
        <v-col v-if="isYearSelected" cols="3">
          <v-select
            v-model="yearSelected"
            :items="years"
            clas="py-0"
            dense
            label="Año"
            :rules="[(v) => !!v || 'El  Año es obligatorio']"
            outlined
          ></v-select
        ></v-col>
        <!-- <v-spacer></v-spacer> -->

        <v-col cols="3">
          <v-btn class="" color="secundary" type="submit"> Buscar </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <div class="chart mt-8">
      <apexchart
        height="300"
        type="bar"
        :options="chartOptionsBar"
        :series="seriesBar"
      ></apexchart>
    </div>
  </div>
</template>
<script>
import BackendApi from "@/services/backend.service";
import moment from "moment-timezone";

moment().tz("America/Lima").format();

export default {
  name: "bar-chart",
  props: {
    itemsConsumo: {
      type: Array,
      default: () => [],
    },
    deviceDataId: {
      type: Number,
    },
    unidad: {
      type: String,
    },
    service: {
      type: String,
    },
  },
  data: function () {
    return {
      chartOptionsBar: {
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: true,
            export: {
              png: {
                filename: "Gráfico en PNG",
              },
              svg: {
                filename: "Gráfico en SVG",
              },
            },
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        xaxis: {
          categories: ["2023"],
        },
        colors: [
          this.service === "agua"
            ? "#1F429B"
            : this.service === "energia"
            ? "#FF914D"
            : "#8C52FF",
        ],
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      seriesBar: [
        {
          name: "Caudal",
          data: [],
        },
      ],
      typeConsumo: null,
      barChartId: "bar-chart",
      typeOfSearch: null,
      typesOfSearch: [
        {
          id: 1,
          value: "año",
        },
        {
          id: 2,
          value: "mes",
        },
      ],
      yearSelected: null,
      years: [],
      monthSelected: null,
      months: [],

      type: "bar",
      data: {
        labels: [],
        datasets: [
          {
            label: "",
            weight: 5,
            borderWidth: 0,
            borderRadius: 4,
            backgroundColor: "#3A416F",
            data: [],
            fill: false,
            maxBarThickness: 35,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              padding: 10,
              color: "#9ca2b7",
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: true,
              drawTicks: true,
            },
            ticks: {
              display: true,
              color: "#9ca2b7",
              padding: 10,
            },
          },
        },
      },
    };
  },
  computed: {
    typeFilterSlected() {
      return true;
    },
    isYearSelected() {
      if (!this.typeOfSearch) return false;
      if (this.typeOfSearch === 1) return true;
      if (this.typeOfSearch === 2) return true;
      else return false;
    },
    isMonthSelected() {
      if (!this.typeOfSearch) return false;
      if (this.typeOfSearch === 2) return true;
      else return false;
    },
  },
  mounted() {
    this.years = this.$store.state.report.years;
    this.months = this.$store.state.report.months;
  },
  methods: {
    async searchDeviceData() {
      const date = new Date();
      const month = date.getMonth() + 1;

      if (this.$refs.formBarChart.validate() && this.deviceDataId) {
        const payload = {
          type: this.typeOfSearch,
          date: this.yearSelected,
          month:
            this.typeOfSearch == 1
              ? null
              : this.yearSelected == date.getFullYear()
              ? month
              : 12,
        };
        BackendApi.post(
          "/deviceData/deviceDataFordate/" + this.deviceDataId,
          payload
        )
          .then((response) => {
            if (response.data.success) {
              let dataCaudaly = response.data.restaCaudal;

              if (this.typeOfSearch == 1) {
                dataCaudaly = dataCaudaly.toFixed(3);
              } else {
                dataCaudaly = dataCaudaly.map((valor) => valor.toFixed(3));
              }

              const dataVolumeny = response.data.restaVolumen;
              const datax = [];

              if (this.typeOfSearch == 2) {
                console.log(
                  "#####################--------------------#################"
                );
                console.log(dataCaudaly);

                for (let i = 0; i < dataVolumeny.length; i++) {
                  const NombreMeses = this.months[i].value;
                  datax.push(NombreMeses);
                }

                this.chartOptionsBar = {
                  chart: {
                    height: 350,
                    type: "bar",
                    toolbar: {
                      show: true,
                      export: {
                        png: {
                          filename: "Gráfico en PNG",
                        },
                        svg: {
                          filename: "Gráfico en SVG",
                        },
                      },
                    },
                  },
                  plotOptions: {
                    bar: {
                      borderRadius: 10,
                      dataLabels: {
                        position: "top", // top, center, bottom
                      },
                    },
                  },
                  dataLabels: {
                    enabled: true,
                    formatter: (val) =>
                      this.typeConsumo === 1
                        ? val + " " + this.unidad
                        : val + " " + this.unidad + "/h",
                    offsetY: -20,
                    style: {
                      fontSize: "12px",
                      colors: ["#304758"],
                    },
                  },

                  xaxis: {
                    categories: datax,
                  },
                  yaxis: {
                    axisBorder: {
                      show: false,
                    },
                    axisTicks: {
                      show: false,
                    },
                    labels: {
                      show: false,
                      formatter: (val) =>
                        this.typeConsumo === 1
                          ? val + " " + this.unidad
                          : val + " " + this.unidad + "/h",
                    },
                  },
                };
                if (this.typeConsumo == 1) {
                  this.seriesBar = [
                    {
                      name: "Volumen",
                      data: dataVolumeny,
                    },
                  ];
                }
                if (this.typeConsumo == 2) {
                  this.seriesBar = [
                    {
                      name: "Caudal",
                      data: dataCaudaly,
                    },
                  ];
                }
              }
              if (this.typeOfSearch == 1) {
                this.chartOptionsBar = {
                  chart: {
                    height: 350,
                    type: "bar",
                    toolbar: {
                      show: true,
                      toolbar: {
                        show: true,
                        export: {
                          png: {
                            filename: "Gráfico en PNG",
                          },
                          svg: {
                            filename: "Gráfico en SVG",
                          },
                        },
                      },
                    },
                  },
                  plotOptions: {
                    bar: {
                      borderRadius: 10,
                      dataLabels: {
                        position: "top", // top, center, bottom
                      },
                    },
                  },
                  dataLabels: {
                    enabled: true,
                    formatter: (val) =>
                      this.typeConsumo === 1
                        ? val + " " + this.unidad
                        : val + " " + this.unidad + "/h",
                    offsetY: -20,
                    style: {
                      fontSize: "12px",
                      colors: ["#304758"],
                    },
                  },

                  xaxis: {
                    categories: [this.yearSelected],
                  },
                  yaxis: {
                    axisBorder: {
                      show: false,
                    },
                    axisTicks: {
                      show: false,
                    },
                    labels: {
                      show: false,
                      formatter: (val) =>
                        this.typeConsumo === 1
                          ? val + " " + this.unidad
                          : val + " " + this.unidad + "/h",
                    },
                  },
                };
                if (this.typeConsumo == 1) {
                  this.seriesBar = [
                    {
                      name: "Volumen",
                      data: [dataVolumeny],
                    },
                  ];
                }
                if (this.typeConsumo == 2) {
                  this.seriesBar = [
                    {
                      name: "Caudal",
                      data: [dataCaudaly],
                    },
                  ];
                }
              }
            }
          })
          .catch((e) => {
            console.log(console.log(e));
          });
      }
    },
    changeTypesOfSearch() {
      this.monthSelected = null;
    },
    changeYear() {},
    changeMonth() {},
  },
};
</script>
